<template>
  <button
    class="
      p0 bg-cl-primary brdr-1 brdr-cl-primary
      brdr-square h5 cl-black weight-400 size-selector
    "
    :class="{ active: isActive }"
    @click="$emit('change', variant)"
    :aria-label="variant.label"
  >
    {{ label }}
  </button>
</template>

<script>
import filterMixin from 'theme/mixins/filterMixin.ts'

export default {
  mixins: [filterMixin]
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-active: color(primary-orange);
  $color-disabled: color(secondary, $colors-border);
  $color-text: color(black);

  .size-selector {
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    min-width: 50px;
    height: 30px;
    &:hover,
    &:focus {
      border-width: 2px;
    }

    &.active {
      border-color: $color-active;
      border-width: 2px;
      color: $color-text;
    }

    &:disabled {
      border-color: $color-disabled;
      color: $color-disabled;
      cursor: not-allowed;

      &:hover,
      &:after {
        border-width: 1px;
      }
    }
    &.not-eccomerce {
      display: none;
      // border-color: $color-disabled;
      // color: $color-disabled;
      // cursor: not-allowed;
      // position: relative;
      // border-width: 1px;
      // &:after {
      //   content: 'NZ';
      //   position: absolute;
      //   left: 0;
      //   font-size: 12px;
      //   right: 0;
      //   top: 7px;
      //   color: #FF9800;
      // }
    }
    &.disabled {
      pointer-events: none;
      border-color: $color-disabled;
      color: $color-disabled;
      cursor: not-allowed;
      border-width: 1px;
      &:hover,
      &:after {
        border-width: 1px;
      }
    }
  }
</style>
