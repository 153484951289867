import * as types from './store/mutation-types'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { getGuaClientId } from 'theme/utils/getGuaClientId'
import { getGuaUa } from 'theme/utils/getGuaUa'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'

const InstantCheckoutStore = {
  namespaced: true,
  state: {
    supported: false
  },
  mutations: {
    [types.SET_SUPPORTED] (state, supported) {
      state.supported = supported
    }
  },
  getters: {
    getSupported: state => state.supported
  }
}

export const KEY = 'instant-checkout'

export const InstantCheckoutModule: StorefrontModule = function ({store}) {
  store.registerModule('instant-checkout', InstantCheckoutStore)

  let instantCheckout = false

  function isInstantCheckoutAvailable () {
    if (typeof (window as any).PaymentRequest === 'undefined') return
    if (config.payment_payu.instant_checkout.method_enabled.includes('GooglePay') || (config.payment_payu.instant_checkout.method_enabled.includes('ApplePay') && typeof (window as any).ApplePaySession !== 'undefined')) {
      store.commit(KEY + '/' + types.SET_SUPPORTED, true)
      instantCheckout = true
    }
  }

  if (!isServer) {
    EventBus.$on('application-after-loaded', () => {
      if (!instantCheckout) {
        isInstantCheckoutAvailable()
      }
    })
  }
}
