<template>
  <div id="number-input" class="flex end-xs">
    <div class="counter mr20">
      <!-- <label class="base-input-number__label cl-primary flex" :for="getInputId">{{ name }}</label> -->
      <i class="custom-icons" @click="$emit('click', decreaseValue)">&#8722;</i>
      <input
        :id="getInputId"
        type="number"
        min="1"
        max="99"
        aria-label="number input"
        :disabled="disabled"
        class="m0 no-outline base-input-number__input brdr-cl-primary bg-cl-transparent h4"
        :focus="autofocus"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
      >
      <i class="custom-icons" @click="$emit('click', increaseValue)">&#43;</i>
      <ValidationMessages v-if="validations" :validations="validations" />
    </div>
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'
export default {
  name: 'BaseInput',
  components: {
    ValidationMessages
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getInputId () {
      return `input_${this._uid}`
    },
    decreaseValue () {
      var newValue = Number(this.value)
      if (newValue < 2) {
        newValue = 1
      } else {
        newValue -= 1
      }
      return newValue
    },
    increaseValue () {
      var newValue = Number(this.value)
      if (newValue < 99) {
        newValue += 1
      } else {
        newValue = 99
      }
      return newValue
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .counter {
    input[type="number"] {
      height: 30px !important;
      line-height: 25px !important;
      width: 26px !important;
      font-size: 22px !important;
    }
    i.custom-icons {
      font-size: 24px !important;
      height: 30px !important;
      width: 28px !important;
    }
  }
}
div#number-input {
  // margin-bottom: 1em;
  .counter {
    display: flex;
    background: white;
    border: 1px solid #C6C6C6;
    align-items: center;
    // border-radius: 30px;
    input[type="number"] {
      border-left: 1px solid #C6C6C6 !important;
      border-right: 1px solid #C6C6C6 !important;
      border: 0;
      text-align: center;
      height: 30px;
      padding: 0;
      line-height: 30px;
      font-size: 22px;
      width: 30px;
      color: #707070;
      border-radius: 0;
    }
    input[type="number"]:focus,
    i:focus {
      outline: none;
    }
    i.custom-icons {
      padding: 0;
      color: #707070;
      font-size: 28px;
      text-align: center;
      height: 30px;
      width: 28px;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
</style>
