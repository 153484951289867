var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-quantity"},[_c('base-input-number',{attrs:{"name":_vm.name,"value":_vm.value,"min":1,"max":_vm.max,"disabled":false,"validations":[
      {
        condition: !_vm.$v.value.numeric || !_vm.$v.value.minValue || !_vm.$v.value.required,
        text: _vm.$t("Quantity must be positive integer")
      },
      {
        condition: _vm.maxQuantity && _vm.value && !_vm.$v.value.maxValue,
        text: _vm.$t('Quantity must be below {quantity}', { quantity: _vm.maxQuantity })
      }
    ]},on:{"input":function($event){return _vm.$emit('input', $event)},"blur":function($event){return _vm.$v.$touch()},"click":function($event){return _vm.$emit('click', $event)}}}),_vm._v(" "),(_vm.loading)?_c('spinner'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }